<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Detail Langganan'">
          <template v-slot:body>
            <div class="row align-items-center">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="
                    d-flex
                    justify-content-center
                    align-items-center
                    image-container
                  "
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      src="/images/undraw_medical_care_movn.svg"
                      @load="onImageLoad()"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col-md">
                <table class="table mb-3">
                  <tr>
                    <td style="width: 25%"><strong>No. Invoice</strong></td>
                    <td>{{ data.display_invoice_id }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama Klinik</strong></td>
                    <td>{{ data.tenant_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Paket Yang Diambil</strong></td>
                    <td>{{ data.subscription_package_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Durasi Paket (Hari)</strong></td>
                    <td>{{ data.subscription_package_duration }}</td>
                  </tr>
                  <tr>
                    <td><strong>Harga</strong></td>
                    <td>
                      {{
                        parseInt(
                          data.subscription_package_price
                        ).toLocaleString("id-ID")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Pajak</strong></td>
                    <td>
                      {{ parseInt(data.tax_amount).toLocaleString("id-ID") }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Diskon</strong></td>
                    <td>
                      {{ parseInt(data.discount_amount).toLocaleString("id-ID") }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Total</strong></td>
                    <td>
                      {{ parseInt(data.display_total).toLocaleString("id-ID") }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Metode Pembayaran</strong></td>
                    <td>{{ data.payment_bank }}</td>
                  </tr>
                  <tr>
                    <td><strong>Satus Pembayaran</strong></td>
                    <td>
                      <b-badge
                        pill
                        variant="success"
                        v-if="data.workstate_name == 'Paid'"
                        >Lunas</b-badge
                      >
                      <b-badge
                        pill
                        variant="warning"
                        v-if="data.workstate_name == 'Unpaid'"
                        >Belum Lunas</b-badge
                      >
                    </td>
                  </tr>
                 
                </table>
                <!-- <button
                  @click="
                    $router.push({
                      name: 'donor-edit',
                      params: { id: data.id },
                    })
                  "
                  class="btn mx-1 btn-success"
                  v-if="btn"
                >
                  <i class="far fa-fw fa-edit"></i> Edit
                </button> -->
                <!-- <button
                  class="btn mx-1 btn-danger"
                  v-if="btn"
                ><i class="far fa-fw fa-trash-alt"></i> Hapus</button> -->
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import saasModule from "@/core/modules/CrudSaasModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Card,
  },

  data() {
    return {
      // Data
      data: [],
      // Other
      imgLoaded: false,
      // User Access
      btn: false,
    };
  },

  methods: {
    onImageLoad() {
      this.imgLoaded = true;
    },

    // async deleteData() {
    //   let result = await saasModule.delete('billings/' + this.$route.params.id)
    //   // If Deleted
    //   if (result) {
    //     // Redirect To List
    //     this.$router.push('/donors')
    //   }
    // },

    async get() {
      this.data = await saasModule.get("billings/" + this.$route.params.id);
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push("/billings/list");
      }
    },

    // setActiveButton(){
    //   let access_right_user = window.localStorage.getItem("access_right_display")
    //   let access_right = JSON.parse(access_right_user)

    //   let a
    //   for(a = 0; a < access_right.length; a++){
    //     if(access_right[a] == "2202"){
    //       this.btn = true
    //     }
    //   }
    // },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Berlangganan", route: "/billings/list" },
      { title: "Detail" },
    ]);

    this.get();
    // this.setActiveButton();
  },
};
</script>

<style>
/* Dynamic Size Image */
.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
</style>